/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { merchantName } from "../../../utils/info";
import { translate } from "../../../utils/translate";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const lang = props.lang;
  const merchant_code = (props.auth.auth.merchant_code) ? props.auth.auth.merchant_code : "";
  const user_type = (props.auth.auth.user_type) ? props.auth.auth.user_type : "";

  // const merchant_id = props.auth.auth.merchant_id;
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    getMenuToggle,
    openMenuToggle
  } = useContext(ThemeContext);
  const history = useHistory();
  const [path, setPath] = useState("");
  const [pathHeader, setPathHeader] = useState("");
  const [toggle, setToggle] = useState(getMenuToggle())
  const boxRef = useRef(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var rightContainer = document.querySelector(".header-right-container");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    rightContainer.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
      // return aaa.classList.toggle("menu-toggle");
    } else {
      aaabackdrop.classList.remove('d-none');
      return aaa.classList.add("menu-toggle");
    }
  }
  let scrollPosition = useScrollPosition();
  /// Path
  // path = path.split("/");
  // path = path[path.length - 1];

  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);
      console.log(location.pathname.split('/')[1])
      // console.log(location.pathname, settings, 'path', settings.indexOf(location.pathname));
      setPath(location.pathname);
    });
    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history]);

  function onLogout() {
    localStorage.removeItem('wapps-admin');
    window.location.href = '/login';
  }

  useEffect(() => {
    // console.log("refresh", pathHeader)
  }, [pathHeader])
  /// Active menu
  let dashBoard = ["dashboard"],
    member = ["member"],
    agent = ["agent"],
    settings = ["settings"],
    merchant = ["merchant"],
    questionair = ["questionair"],
    product = ["product"],
    event = ["event"],
    customer = ["customer"],
    custody = ["custody"],
    custodyList = ["custody_list"],
    report = ["report"],
    live_band = ["live_band"],
    merchant_setting = ["merchant_setting"];
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
      ref={boxRef}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${path === "/" || path.match(/(dashboard)+/i) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/dashboard" >
              <i className="fas fa-chart-pie"></i>
              <span className="nav-text">{translate(lang, 'dashboard')}</span>
            </Link>
            {/* <ul >
              <li><Link className={`${path === "" ? "mm-active" : "dashboard"}`} to="/dashboard"> Dashboard Light</Link></li>
              <li>
                <Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark">
                  Dashboard Dark
                </Link>
              </li>
              <li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest</Link></li>
              <li><Link className={`${path === "guest-detail" ? "mm-active" : ""}`} to="/guest-detail">Guest Detail</Link></li>
              <li><Link className={`${path === "concierge" ? "mm-active" : ""}`} to="/concierge">Concierge</Link></li>
              <li><Link className={`${path === "room-list" ? "mm-active" : ""}`} to="/room-list">Room</Link></li>
              <li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
              <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
            </ul> */}
          </li>
          {/* <li className={`${member.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="flaticon-381-user-4"></i>
              <span className="nav-text">{translate(lang, 'member')}</span>
            </Link>
            <ul className={`mm-collapse ${member.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(member)+/i) ? "mm-active" : ""}`} to="/member">{translate(lang, 'list')}</Link></li>
              <li><Link className={`${path === "/member-policy" ? "mm-active" : ""}`} to="/member-policy">{translate(lang, 'policy')}</Link></li>
            </ul>
          </li> */}
          {/* <li className={`${agent.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user-2"></i>
              <span className="nav-text">{translate(lang, 'agent')}</span>
            </Link>
            <ul className={`mm-collapse ${agent.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(agent)+/i) ? "mm-active" : ""}`} to="/agent">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {/* <li className={`${merchant.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user"></i>
              <span className="nav-text">{translate(lang, 'merchant')}</span>
            </Link>
            <ul className={`mm-collapse ${merchant.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(merchant)+/i) ? "mm-active" : ""}`} to="/merchant">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {/* <li className={`${questionair.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-list-1"></i>
              <span className="nav-text">{translate(lang, 'questionnaire')}</span>
            </Link>
            <ul className={`mm-collapse ${questionair.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(questionair)+/i) ? "mm-active" : ""}`} to="/questionair">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {/* <li className={`${product.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user-2"></i>
              <span className="nav-text">{translate(lang, 'productList')}</span>
            </Link>
            <ul className={`mm-collapse ${product.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(product)+/i) ? "mm-active" : ""}`} to="/product">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {(merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender')
            ?
            <></>
            :
            <>
              <li className={`${product.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/product" >
                  <i className="fas fa-beer"></i>
                  <span className="nav-text">{translate(lang, 'product')}</span>
                </Link>
              </li>
            </>
          }
          <li className={`${custody.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/custody" >
              <i className="fas fa-box"></i>
              <span className="nav-text">{translate(lang, 'custody')}</span>
            </Link>
          </li>
          <li className={`${custodyList.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/custody_list" >
              <i className="fas fa-glass-cheers"></i>
              <span className="nav-text">{translate(lang, 'custodyList')}</span>
            </Link>
          </li>
          <li className={`${customer.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/customer" >
              <i className="fas fa-users"></i>
              <span className="nav-text">{translate(lang, 'customer')}</span>
            </Link>
          </li>
          {/* <li className={`${event.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user-2"></i>
              <span className="nav-text">{translate(lang, 'event')}</span>
            </Link>
            <ul className={`mm-collapse ${event.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(event)+/i) ? "mm-active" : ""}`} to="/event">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {(merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender')
            ?
            <></>
            :
            <>
              <li className={`${event.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/event" >
                  <i className="fas fa-calendar-week"></i>
                  <span className="nav-text">{translate(lang, 'event')}</span>
                </Link>
              </li>
            </>
          }

          <li className={`${event.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/voucher" >
              <i className="fas fa-ticket-alt"></i>
              <span className="nav-text">{translate(lang, 'voucher')}</span>
            </Link>
          </li>

          {/* <li className={`${live_band.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/live_band" >
              <i className="fas fa-compact-disc"></i>
              <span className="nav-text">{translate(lang, 'liveBand')}</span>
            </Link>
          </li> */}

          <hr />
          {(merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender')
            ?
            <></>
            :
            <>
              <li className={`${merchant_setting.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/merchant_setting" >
                  <i className="fas fa-building"></i>
                  <span className="nav-text">{translate(lang, 'merchantProfile')}</span>
                </Link>
              </li>
            </>
          }

          {(merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender')
            ?
            <></>
            :
            <>
              <li className={`${report.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/report" >
                  <i className="fas fa-folder"></i>
                  <span className="nav-text">{translate(lang, 'report')}</span>
                </Link>
              </li>
            </>
          }

          <li className={`${settings.includes(pathHeader) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="fas fa-cog"></i>
              <span className="nav-text">{translate(lang, 'settings')}</span>
            </Link>
            <ul className={`mm-collapse ${settings.includes(pathHeader) ? "mm-show" : ""}`} >
              {(merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender')
                ?
                <></>
                :
                <>
                  <li><Link className={`${path.match(/(subscription)+/i) ? "mm-active" : ""}`} to="/settings/subscription">{translate(lang, 'subscription')}</Link></li>
                  <li><Link className={`${path.match(/(profile)+/i) ? "mm-active" : ""}`} to="/settings/profile">{translate(lang, 'profile')}</Link></li>
                  <li><Link className={`${path.match(/(staff)+/i) ? "mm-active" : ""}`} to="/settings/staff">{translate(lang, 'staff')}</Link></li>
                  <li><Link className={`${path.match(/(category_1)+/i) ? "mm-active" : ""}`} to="/settings/category_1">{translate(lang, 'category1')}</Link></li>
                  <li><Link className={`${path.match(/(category_2)+/i) ? "mm-active" : ""}`} to="/settings/category_2">{translate(lang, 'category2')}</Link></li>
                  <li><Link className={`${path.match(/(uom)+/i) ? "mm-active" : ""}`} to="/settings/uom">{translate(lang, 'uom')}</Link></li>
                  <li><Link className={`${path.match(/(sms)+/i) ? "mm-active" : ""}`} to="/settings/sms">{translate(lang, 'sms')}</Link></li>
                  <li><Link className={`${path.match(/(misc)+/i) ? "mm-active" : ""}`} to="/settings/misc">{translate(lang, 'misc')}</Link></li>
                </>
              }
              <li><a href="#" onClick={() => onLogout()} >{translate(lang, 'logout')}</a></li>
            </ul>
          </li>
          {/* <li className={`${settings.includes(pathHeader) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-settings-6"></i>
              <span className="nav-text">{translate(lang, 'settings')}</span>
            </Link>
            <ul className={`mm-collapse ${settings.includes(pathHeader) ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(master-policy)+/i) ? "mm-active" : ""}`} to="/settings/master-policy">{translate(lang, 'masterPolicy')}</Link></li>
              <li><Link className={`${path.match(/(policy-category)+/i) ? "mm-active" : ""}`} to="/settings/policy-category">{translate(lang, 'policyCategory')}</Link></li>
              <li><Link className={`${path.match(/(rider)+/i) ? "mm-active" : ""}`} to="/settings/rider">{translate(lang, 'rider')}</Link></li>
              <li><Link className={`${path.match(/(merchant)+/i) ? "mm-active" : ""}`} to="/settings/merchant">{translate(lang, 'merchant')}</Link></li>
            </ul>
          </li> */}
        </MM>
        <div className="copyright">
          <p><strong>{merchantName}</strong> © 2023 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(SideBar);
